import { standardFormat } from "../../../../src/utils/dateFormatting";

function mutatePayload({payload, data, partnerValues, pilotsValues, internalCheck}) {
    
    if (internalCheck) {
        payload.mission_start_date = standardFormat(data.mission_start_date)
        payload.mission_end_date = standardFormat(data.mission_end_date)
        payload.budget = data.budget
        // payload.origin = data.origin
    }

    if (data.status.id === 0 || data.status.id >= 300) {
        payload.production_lead_id = data.production_lead.id;
    }

    if (data.status.id < 300) {

        const baseValue = Math.floor(100 / payload.sales.length);``

        const remainder = 100 % payload.sales.length;

        const valuesArray = Array(payload.sales.length).fill(baseValue);
        
        for (let i = 0; i < remainder; i++) {
            valuesArray[i] += 1;
        }

        payload.sales = payload.sales.map((sale, index) => {
            return {...sale, percent: valuesArray[index]}
        })
    }

    if (data.status.id >= 150) {
        payload.reception_date = standardFormat(data.reception_date);
        payload.analysis_date = standardFormat(data.analysis_date);
    }

    if (data.status.id >= 200) {
        payload.max_response_date = standardFormat(data.max_response_date);
    }

    if (data.status.id >= 300 && data.deal_type.value === "business") {
        payload.chance = data.chance.id;
        payload.response_date = standardFormat(data.response_date);

        // --------------------------------------------
        // ↓ Budget ↓
        // --------------------------------------------
        payload.budget = data.budget_global;
        payload.billable_days = data.billable_days_global;
        payload.budget_vat = data.vat_rate_global;
        payload.expense_budget = data.expense_budget;
        payload.procurement_cost = data.procurement_cost;
        payload.commission = data.commission;
        data.agreement_budget ? payload.agreement_budget = data.agreement_budget : payload.agreement_budget = 0;
        
        // --------------------------------------------
        // ↓ Production ↓
        // --------------------------------------------

        payload.mission_start_date = standardFormat(data.mission_start_date);
        payload.mission_end_date = standardFormat(data.mission_end_date);

        // --------------------------------------------
        // ↓ Response groupement, add deal contractors ↓
        // --------------------------------------------

        if (partnerValues[0].ressource) {
            payload.contractors = partnerValues.map((partner) => {
                return {
                    budget: data[`partner_budget_${partner.ressource.name}`],
                    direct: data[`payment_${partner.ressource.name}`].direct,
                    contractor: partner.ressource,
                    contractor_id: partner.ressource.id,
                    budget_vat: data[`vat_rate_${partner.ressource.name}`],
                    // billable_days: data[`billable_days_${partner.ressource.name}`],
                    is_cocontractor: partner.role.id === 1 ? true : false
        
                }
            })
        }

        // --------------------------------------------
        // ↓ Pilotes du budget bilotage ↓
        // --------------------------------------------
        if (pilotsValues[0].ressource) {
            payload.agreement_leads = pilotsValues.map((partner, index) => {
                return {
                    budget: data[`agreement_budget_${index}`],
                    daily_rate: data[`agreement_daily_rate_${index}`],
                    ressource_id: data[`agreement_lead_${index}`].id
                }
            })
        }
        
    }

    if (data.status.id >= 300 && data.deal_type.value === "purchase_order" ||
        data.status.id >= 300 && data.deal_type.value === "framework"
    ) {
        
        payload.chance = data.chance.id;
        payload.response_date = standardFormat(data.response_date);

        data.min_budget ? payload.min_budget = data.min_budget : payload.min_budget = 0;
        data.max_budget ? payload.max_budget = data.max_budget : payload.max_budget = 0;

        data.min_days_of_intervention ? payload.min_days_of_intervention = data.min_days_of_intervention : payload.min_days_of_intervention = 0;
        data.max_days_of_intervention ? payload.max_days_of_intervention = data.max_days_of_intervention : payload.max_days_of_intervention = 0;

        data.min_vat_rate ? payload.min_vat_rate = data.min_vat_rate : payload.min_vat_rate = 0;
        data.max_vat_rate ? payload.max_vat_rate = data.max_vat_rate : payload.max_vat_rate = 0;

        // --------------------------------------------
        // ↓ Response groupement, add deal contractors ↓
        // --------------------------------------------

        if (partnerValues[0].ressource) {
            payload.contractors = partnerValues.map((partner) => {
                return {
                    budget: data[`partner_budget_${partner.ressource.name}`],
                    direct: data[`payment_${partner.ressource.name}`].direct,
                    contractor: partner.ressource,
                    contractor_id: partner.ressource.id,
                    budget_vat: data[`vat_rate_${partner.ressource.name}`],
                    // billable_days: data[`billable_days_${partner.ressource.name}`],
                    is_cocontractor: partner.role.id === 1 ? true : false
        
                }
            })
        }

    }

    if (data.status.id === 500) {
        payload.mission_start_date = standardFormat(data.mission_start_date);
    }

    return payload;
}

export default mutatePayload