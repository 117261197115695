const roadStepDealsConfig = (isInternal, isTraining, dealData) => {
    
    const cannotBeModified = dealData.status === 900;
    const won = dealData.status >= 500 && !dealData.loss_motive;
    
    const lost = dealData.status >= 400 && dealData.loss_motive;

    //pass deal status and step status to function
    // return true if status less or equal to deal status
    //false otherwise
    // always true if deal status = 900

    const isStepDisabled = (step) => {
        if (dealData.status === 900) return true;

        if (step <= dealData.status) return true;

        return false;
    }

    return (
        {
            color: isTraining ? 'blue' : 'orange',
            width: isInternal ? 'w-[40%]' : 'w-[85%]',
            items: isInternal ?
                [
                    { title: 'En cours', disabled: cannotBeModified },
                    { title: 'Terminée', disabled: cannotBeModified }
                ]
                :
                [
                    { title: 'CdC demandé', disabled: isStepDisabled(100) },
                    { title: 'Étude CdC', disabled: isStepDisabled(150) },
                    { title: dealData.status <= 150 ? "Résultat d'analyse" : "Réponse en cours", disabled: isStepDisabled(200) },
                    { title: 'Offre remise', disabled: isStepDisabled(300) },
                    { title: won ? 'Gagnée' : lost ? 'Perdue' : 'Résultat', disabled: isStepDisabled(500)},
                    { title: 'Produite', disabled: isStepDisabled(600) },
                    { title: 'Facturée', disabled: isStepDisabled(700) },
                    { title: 'Close', disabled: isStepDisabled(900) }
                ]
        }
    );
}

export default roadStepDealsConfig;
